(function($){
    if (!Object.entries) {
        Object.entries = function( obj ){
            var ownProps = Object.keys( obj ),
                i = ownProps.length,
                resArray = new Array(i); // preallocate the Array
            while (i--)
                resArray[i] = [ownProps[i], obj[ownProps[i]]];

            return resArray;
        };
    }

    $.fn.form_block = function(){
        // this.block({
        //     message: null,
        //     overlayCSS: {
        //         background: '#fff',
        //         opacity: 0.6
        //     }
        // });
    };

    $.fn.form_unblock = function(){
        // this.unblock();
    };


    var $body = $('body');

    var $fn_set_booking_dates = function($date){
        var $date_parts = $date.split('/');

        $('.wc-bookings-date-picker-date-fields .booking_date_day').val($date_parts[0]);
        $('.wc-bookings-date-picker-date-fields .booking_date_month').val($date_parts[1]);
        $('.wc-bookings-date-picker-date-fields .booking_date_year').val($date_parts[2]);
        $('.wc-bookings-date-picker-date-fields .booking_date_day').trigger('change');
    };

    $body.bind('init_course_locations', function($e, $form_id, $form){
        var $location_control = $('.gfield-course-location select', $form);

        if($location_control.length){
            $form.form_block();
            var $field_course_days = $('.gfield-course-days', $form);
            var $field_html_id = $field_course_days.attr('id');
            var $field_id = $field_html_id.split('_')[2];
            var $ul_day_radios = $('.gfield-course-days ul.gfield_radio', $form);

            $location_control.on('change', function(){

                var $location_id = $(this).val();

                var $selected_day = $field_course_days.find('input:checked').val();

                $ul_day_radios.empty();

                if(window.$course_schedule_json){
                    var $schedule_days = $course_schedule_json["" + $location_id];
                    if($schedule_days){
                        var $input_idx = -1;
                        for(var $i=0; $i<$schedule_days.length; $i++){
                            $input_idx++;
                            var $li = $('<li class="gchoice_'+$form_id+'_'+$field_id+'_'+ $input_idx +'"></li>');
                            var $li_input = $('<input type="radio" name="input_'+$field_id+'" id="choice_'+$form_id+'_'+$field_id+'_'+ $input_idx +'">');
                            var $li_label = $('<label for="choice_'+$form_id+'_'+$field_id+'_'+ $input_idx +'"></label>');

                            $li_input.val($schedule_days[$i]['value']);
                            if($selected_day == $schedule_days[$i]['value'])
                                $li_input.prop('checked', 'checked');

                            $li_label.text(" " + $schedule_days[$i]['text']);

                            $li.append($li_input);
                            $li.append($li_label);

                            $ul_day_radios.append($li);
                        }
                    }
                }
            }).trigger('change');
            $form.form_unblock();
        }
    });

    $body.bind('init_course_multiple_datepicker', function($e, $form, $current_page){
        var $multiple_dates_field = $('.gfield-multiple-dates', $form);
        var $multiple_dates_control = $('.gfield-multiple-dates input', $form);

        if($multiple_dates_field.length){

            if($current_page == 1){
                $multiple_dates_field.find('.ginput_container').multiDatesPicker({
                    'altField': $multiple_dates_field.find('input'),
                    'dateFormat': "dd-mm-yy",
                    'firstDay': 1,
                    'dayNamesMin': [ "S", "M", "T", "W", "T", "F", "S" ],
                    'onSelect': function(date) {
                        var $dates = $multiple_dates_control.val();
                        if($dates){
                            var $first_date = $dates.split(', ')[0];
                            $fn_set_booking_dates($first_date);
                        }
                    }

                });
            }

        }
    });

    $body.bind('init_course_date', function($e, $form, $current_page){
//        var $date_control = $('.gfield-course-date input', $form);
//
//        if($date_control.length){
//
//            if($current_page == 1){
//                $date_control.datepicker({
//                    'dateFormat': "dd-mm-yy",
//                    'firstDay': 1,
//                    'dayNamesMin': [ "S", "M", "T", "W", "T", "F", "S" ],
//                    'onSelect': function(date) {
//                        var $dates = $date_control.val();
//                        if($dates){
//                            $fn_set_booking_dates($dates);
//                        }
//                    },
//                    beforeShowDay: function(date){
//                        if(date < (new Date).getDate()){
//                            return [0];
//                        }
//
//                        if(window.$block_dates){
//                            var string = jQuery.datepicker.formatDate('yy-mm-dd', date);
//                            return [ $block_dates.indexOf(string) == -1 ]
//                        }
//
//                    }
//
//                });
//            }
//
//        }
    });

    $body.bind('init_course_require_login', function($e, $form){
        /*
        var $email_control = $('.gfield-email input', $form);

        if($email_control.length){
            $email_control.on('blur', function(){
                var $email = $email_control.val();

                if(!$email)
                    return;

                $email_control.addClass('loading');
                window.is_validating = true;

                $.ajax({
                    'url': PRDT.ajax_url,
                    'dataType': 'html',
                    'method': 'post',
                    'data': {
                        'action': 'validate_enroller_email',
                        'email': $email
                    }
                }).done(function($response){
                    if($response != '1'){
                        $('.login-modal #username').val($email);
                        $.show_login();
                    }

                }).fail(function(){

                }).always(function(){
                    window.is_validating = false;
                    $email_control.removeClass('loading');
                });

            });
        }
        */
    });

    $body.bind('init_course_autocomplete_enrollees', function($e, $form){

        var $dog_control = $('.gfield-enrollee-name input', $form);
        if($dog_control.length && window.$enrollees_json){
            $dog_control.each(function(){
                var $me = $(this);
                var $parent = $me.parent();
                var $div = $('<div class="enrollees-tags">Select your pets: </div>');

                $enrollees_json.forEach(function(item, i){
                    if(item) {
                        var $a = $('<a href="#" class=""></a>');
                        $a.text(item.label);
                        $a.data('item', item);

                        $div.append($a);
                    }
                });

                var $a = $('<a href="#" class=""></a>');
                $a.text('+New pet');
                $a.data('item', {});
                $div.append($a);

                $parent.after($div);

                /*
                $me.autocomplete({
                    minLength: 0,
                    source: $enrollees_json,
                    focus: function( event, ui ) {
                        $me.val( ui.item.label );
                        return false;
                    },
                    select: function( event, ui ) {

                        var $item = ui.item;

                        $me.val( $item.label );

                        var $li = $me.closest('li.gfield');
                        var $next_all = $li.nextAll();

                        $next_all.filter('.gfield-enrollee-breed').eq(0).find('input').val($item.breed);
                        $next_all.filter('.gfield-enrollee-dob').eq(0).find('input').val($item.dob);
                        $next_all.filter('.gfield-enrollee-gender').eq(0).find('input[value="' + $item.sex + '"]').trigger('click');
                        $next_all.filter('.gfield-enrollee-desexed').eq(0).find('input[value="' + $item.desexed + '"]').trigger('click');
                        $next_all.filter('.gfield-enrollee-vaccination').eq(0).find('select').val($item.vaccination);
                        $next_all.filter('.gfield-enrollee-vaccination').eq(0).find('select').trigger('change');
                        $next_all.filter('.gfield-enrollee-vaccination-date').eq(0).find('input').val($item.vaccination_date);

                        return false;
                    }
                })
                */
            });

        }
    });

    $body.on('click', '.enrollees-tags a', function(e){
        e.preventDefault();

        var $item = $(this).data('item');
        var $li = $(this).closest('li.gfield');
        var $next_all = $li.nextAll().add($li);

        if(!$item)
            return false;

        for( var key in window.$enrollees_map ) {
            var field_class = window.$enrollees_map[key];
            var $field = $next_all.filter('.' + field_class).eq(0);
            if($field.length && $item[key]){
                var $container = $field.find('.ginput_container');
                if($container.hasClass('ginput_container_radio')){
                    $container.find('input[value="' + $item[key] + '"]').trigger('click');
                }else if($container.hasClass('ginput_container_select')){
                    $container.find('select').val($item[key]);
                    $container.find('select').trigger('change');
                }else if($container.hasClass('ginput_container_checkbox')){
                    var $issues_inputs = $container.find('input');
                    $issues_inputs.prop('checked', false);
                    $issues_inputs.eq(0).trigger('change');
                    if($item[key]){
                        var issues = $item[key].split(',');

                        issues.forEach(function(text,i){
                            text = $.trim(text);
                            $issues_inputs.each(function(i, input){
                                if(text === $.trim(input.value)){
                                    $(input).prop('checked', 'checked');
                                    $(input).trigger('change');
                                    return false;
                                }
                            });
                        });
                    }
                }else if($container.hasClass('ginput_container_fileupload')){
                    // skip
                }else{
                    $container.find('input,textarea').val($item[key]);
                }
            }
        }

        /*
        $next_all.filter('.gfield-enrollee-name').eq(0).find('input').val($item.label);
        $next_all.filter('.gfield-enrollee-species').eq(0).find('select').val($item.species);
        $next_all.filter('.gfield-enrollee-species').eq(0).find('select').trigger('change');
        $next_all.filter('.gfield-enrollee-breed').eq(0).find('input').val($item.breed);
        $next_all.filter('.gfield-enrollee-dob').eq(0).find('input').val($item.dob);
        $next_all.filter('.gfield-enrollee-gender').eq(0).find('input[value="' + $item.sex + '"]').trigger('click');
        $next_all.filter('.gfield-enrollee-desexed').eq(0).find('input[value="' + $item.desexed + '"]').trigger('click');
        $next_all.filter('.gfield-enrollee-vaccination').eq(0).find('select').val($item.vaccination);
        $next_all.filter('.gfield-enrollee-vaccination').eq(0).find('select').trigger('change');
        $next_all.filter('.gfield-enrollee-vaccination-date').eq(0).find('input').val($item.vaccination_date);
        $next_all.filter('.gfield-enrollee-vaccination-other').eq(0).find('input').val($item.vaccination_other);
        $next_all.filter('.gfield-enrollee-buy-from').eq(0).find('select').val($item.where_did_you_buy_pet_from);
        $next_all.filter('.gfield-enrollee-buy-from').eq(0).find('select').trigger('change');
        $next_all.filter('.gfield-enrollee-supplier-no').eq(0).find('input').val($item.supplier_no);
        $next_all.filter('.gfield-enrollee-specify-other').eq(0).find('input').val($item.specify_other);
        $next_all.filter('.gfield-enrollee-rescue-shelter').eq(0).find('input').val($item.rescue_shelter);

        var $issues_inputs = $next_all.filter('.gfield-enrollee-behavioural-issues').eq(0).find('input');
        $issues_inputs.prop('checked', false);
        $issues_inputs.eq(0).trigger('change');
        if($item.behavioural_issues){
            var issues = $item.behavioural_issues.split(',');

            issues.forEach(function(text,i){
                text = $.trim(text);
                $issues_inputs.each(function(i, input){
                    if(text == input.value){
                        $(input).prop('checked', 'checked');
                        $(input).trigger('change');
                        return false;
                    }

                });
            });
        }

        $next_all.filter('.gfield-enrollee-behavioural-issues-description').eq(0).find('textarea').val($item.behavioural_issues_description);
        */

        return false;

    });

    $body.bind('init_course_require_tos', function($e, $form, $current_page){
        var $form_pages = $form.find('.gform_page');

        if($current_page && $current_page == $form_pages.length || $form_pages.length == 0){

            $body.on('click', '.gfield-course-tos input', function($e){
                $e.preventDefault();
                $e.stopPropagation();

                if($(this).is(':checked')){
                    $('#tos-modal').modal({
                        backdrop: 'static',
                        show: true
                    });

                    $(this).prop('checked', false);
                }

                return false;
            });

            $body.on('click', '#tos-modal .js-tos-agree', function(){
                var $tos_control = $('.gfield-course-tos input');
                $tos_control.prop('checked', true);
                $('#tos-modal').modal('hide');

                return false;
            });
        }
    });

    if(window.gform){
        function is_course_date_available(date){
            if(date < (new Date)){
                return false;
            }

            var $validate = true;
            var $day_names = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            var $current_day_name = $day_names[date.getDay()];

            var $location_type = $('.gfield-training-type select').val();
            var $location_name = $('.gfield-training-location select').val();

            if(window.$unavailable_calendar_dates){
                var string = jQuery.datepicker.formatDate('yy-mm-dd', date);
                $validate = $unavailable_calendar_dates.indexOf(string) == -1;

                if(!$validate)
                    return false;
            }

            if(window.$course_calendar){

                if($location_type == 'In Home')
                    $location_name = 'All';

                var $day = date.getDay();
                var $calendar_slot = false;

                for(var $i=0; $i < $course_calendar.length; $i++){
                    var $row = $course_calendar[$i];

                    if($location_name != $row.location)
                        continue;

                    if($day === 0 && $row.day == 'Sunday'){
                        $calendar_slot = $row;
                        break;
                    }else if($day === 1 && $row.day == 'Monday'){
                        $calendar_slot = $row;
                        break;
                    }else if($day === 2 && $row.day == 'Tuesday'){
                        $calendar_slot = $row;
                        break;
                    }else if($day === 3 && $row.day == 'Wednesday'){
                        $calendar_slot = $row;
                        break;
                    }else if($day === 4 && $row.day == 'Thursday'){
                        $calendar_slot = $row;
                        break;
                    }else if($day === 5 && $row.day == 'Friday'){
                        $calendar_slot = $row;
                        break;
                    }else if($day === 6 && $row.day == 'Saturday'){
                        $calendar_slot = $row;
                        break;
                    }
                }

                if($calendar_slot){
                    if(parseInt($calendar_slot.early_morning_slot) + parseInt($calendar_slot.mid_morning_slot) + parseInt($calendar_slot.early_afternoon_slot) === 0){
                        $validate = false;
                    }
                }else{
                    $validate = false;
                }

                if(!$validate)
                    return false;
            }

            if(window.$course_calendar_home){
                var $location_home_area = $('.gfield-area-within select').val();
                if(!$location_home_area){
                    $validate = false;
                }else{
                    var $found = false;
                    for(var $i = 0; $i<$course_calendar_home.length; $i++){
                        if($course_calendar_home[$i]['location'] == $location_home_area){
                            $found = $course_calendar_home[$i];
                            break;
                        }
                    }

                    if(!$found){
                        $validate = false;
                    }else{
                        if($found['available_days'].indexOf($current_day_name) < 0 ){
                            $validate = false;
                        }
                    }

                }

                if(!$validate){
                    return false;
                }
            }

            if(PRDT.block_days){
                var $week_day = date.getDay();
                var $day = date.getDate();
                var $month = date.getMonth() + 1;
                var $year = date.getFullYear();

                if(PRDT.block_days){
                    var $blocked_days = PRDT.block_days;
                    if($blocked_days.yearly && $blocked_days.yearly.length){
                        var $return = true;
                        $blocked_days.yearly.forEach(function($block){
                            var $from = $block.from.replace('Y', $year);
                            var $to = $block.to.replace('Y', $year);
                            var $from_date = new Date($from);
                            var $to_date = new Date($to);

                            if(date >= $from_date && date <= $to_date){
                                $return = false;
                                return false;
                            }
                        });

                        if(!$return){
                            return false;
                        }

                    }

                    if($blocked_days.monthly && $blocked_days.monthly.length){
                        var $return = true;
                        $blocked_days.monthly.forEach(function($block){
                            var $from = $block.from.replace('Y', $year).replace('m', $month);
                            var $to = $block.to.replace('Y', $year).replace('m', $month);
                            var $from_date = new Date($from);
                            var $to_date = new Date($to);

                            if(date >= $from_date && date <= $to_date){
                                $return = false;
                                return false;
                            }
                        });

                        if(!$return){
                            return false;
                        }
                    }

                    if($blocked_days.weekly && $blocked_days.weekly.days.length){
                        var $return = true;
                        $blocked_days.weekly.days.forEach(function($block){
                            if($week_day == $block){
                                $return = false;
                                return false;
                            }
                        });

                        if(!$return){
                            return false;
                        }
                    }

                    if($blocked_days.specified && $blocked_days.specified.length){
                        var $return = true;
                        $blocked_days.specified.forEach(function($block){
                            var $from = $block.from;
                            var $to = $block.to;
                            var $from_date = new Date($from);
                            var $to_date = new Date($to);


                            if(date >= $from_date && date <= $to_date){
                                $return = false;
                                return false;
                            }
                        });


                        if(!$return){
                            return false;
                        }
                    }

                    if($blocked_days.locations && $blocked_days.locations.length){
                        var $return = true;
                        $blocked_days.locations.forEach(function($block){
                            var $from = $block.from;
                            var $to = $block.to;
                            var $from_date = new Date($from);
                            var $to_date = new Date($to);

                            if($block.location != 'All' && $location_name != $block.location)
                                return true;


                            if(date >= $from_date && date <= $to_date){
                                $return = false;
                                return false;
                            }
                        });


                        if(!$return){
                            return false;
                        }
                    }
                }
            }


            return 1;
        }

        gform.addFilter( 'gform_datepicker_options_pre_init', function( optionsObj, formId, fieldId ) {
            // do stuff

            if(formId == 26 && fieldId == 186){
                optionsObj.beforeShowDay = function(date){
                    var $previous_date = $('#input_26_167').val();
                    if(!$previous_date){
                        return [false];
                    }

                    $previous_date = $previous_date.split('/').reverse().join('/');
                    $previous_date = new Date($previous_date);

                    if(date.getTime() <= $previous_date.getTime())
                        return [false];

                    return [true];
                }
            }else if(formId == 29){
                optionsObj.beforeShowDay = function(date){
                    var $parent = $(this).closest('.gfield-visit-date');
                    if(!$parent.length)
                        return [true];

                    if(date.getTime() < (new Date).getTime())
                        return [false];

                    var $previous_field = $parent.prevAll('.gfield-visit-date').eq(0);
                    if(!$previous_field){
                        return [true];
                    }

                    var $previous_date = $previous_field.find('input').val();
                    if(!$previous_date){
                        return [false];
                    }

                    $previous_date = $previous_date.split('/').reverse().join('/');
                    $previous_date = new Date($previous_date);

                    if(date.getTime() <= $previous_date.getTime())
                        return [false];

                    return [true];
                }
            }

            var $field = $('#field_' + formId + '_' + fieldId);

            if(!$field.hasClass('gfield-course-date'))
                return optionsObj;

            var $default_date = new Date();
            $default_date.setDate($default_date.getDate() + 1);

            var $max_date =  new Date();
            $max_date.setDate($max_date.getDate() + 365);

            optionsObj = $.extend(optionsObj, {
                defaultDate: $default_date,
                maxDate: $max_date,
                beforeShowDay: function(date){
                    return [is_course_date_available(date)];
                }
            });

            return optionsObj;
        } );
    }



    $(document).bind('gform_post_render', function($e, $form_id, $current_page){

        var $form = $('#gform_' + $form_id);

        $body.trigger('init_course_locations', [$form_id, $form]);
        $body.trigger('init_course_date', [$form, $current_page]);
        $body.trigger('init_course_multiple_datepicker', [$form, $current_page]);
        $body.trigger('init_course_require_login', [$form]);
        $body.trigger('init_course_autocomplete_enrollees', [$form]);
        $body.trigger('init_course_require_tos', [$form, $current_page]);

        $form.submit(function(){
            $form.form_block();
            return true;
        });
    });

    var $booking_day_control = $('.wc-bookings-date-picker-date-fields .booking_date_day');
    if($booking_day_control.length){

        $body.on('click', '.gfield-course-days .gfield_radio input', function(){
            var $schedule = $(this).val();
            $fn_set_booking_dates($schedule.split(' ')[0]);
        });

        setTimeout(function(){
            $booking_day_control.trigger('change');
        }, 500);

    }


})(jQuery);
