var $ = jQuery;

$.show_login = function(){
    $('.login-modal').stop().fadeIn();
};

$.hide_login = function(){
    $('.login-modal').stop().hide();
};


(function($, window){
    function preload_pictures($container, $callback, $each_callback) {
        if( $container.length == 0 )
            return;

        var $ = jQuery;
        $container.addClass('loading');

        var $imgs = [];
        $container.find('img').each(function(){
            var $src = $(this).attr('src');
            if( $src )
                $imgs.push( $src );
        });

        var defer = $.imgpreloader({
            paths: $imgs
        });

        defer.always(function($allImages, $properImages, $brokenImages){
            $container.removeClass('loading');
            if($callback)
                $callback($allImages, $properImages, $brokenImages);

        }).progress(function($image, $allImages, $properImages, $brokenImages, isBroken, percentage){
            // $image: jQuery object with an image
            // $allImages: jQuery object with all images
            // $properImages: jQuery object with properly loaded images
            // $brokenImages: jQuery object with broken images
            // isBroken: broken or not
            // percentage: percentage of image loading
            if($each_callback){
                $each_callback($image, $allImages, $properImages, $brokenImages, isBroken, percentage);
            }
        });

    }

    $.fn.parallax_background = function(){
        if(!window.ScrollMagic) return;
        var $controller = new ScrollMagic.Controller({
            globalSceneOptions: {triggerHook: 0, duration: "100%"}
        });

        this.each(function($idx, container){
            var $container = $(container);
            var $content = $container.find('.js-parallax-background__content');
            var $scene = new ScrollMagic.Scene({triggerElement: $container});

            $scene.setTween($content, {y: "30%"})
                //.addIndicators()
                .addTo($controller);
        });
    };


    $(function($){
        var $body = $('body');
        var $window = $(window);

        $('#main-menu').each(function(){
            var $me = $(this);


            var $event_handler = function($e){
                $e.stopPropagation();

                var $direct_sub_menu = $(this).find('>.sub-menu');

                clearTimeout(window._timer_sub_menu);

                // find previous menus
                $(this).prevAll().find('.sub-menu').hide();
                $(this).prevAll().removeClass('hover');
                // find next menus
                $(this).nextAll().find('.sub-menu').hide();
                $(this).nextAll().removeClass('hover');

                if( $direct_sub_menu.length === 0 )
                    return true;

                if( $window.width() < 992 && $direct_sub_menu.is(':visible') ){
                    return true;
                }

                $direct_sub_menu.slideDown();
                $(this).addClass('hover');

                return false;
            };

            if($window.width() < 992){
                $me.find('.menu-item').on( 'click', $event_handler);
            }else{
                $me.find('.menu-item').hover($event_handler, function () {

                });

                $me.hover(function(){

                }, function(){
                    window._timer_sub_menu = setTimeout(function(){
                        $('#main-menu .sub-menu').hide();
                        $('#main-menu .menu-item').removeClass('hover');
                    }, 500);

                });
            }

//            $me.find('.current-menu-item').each(function(){
//                $(this).parents('.menu-item').addClass('current-menu-item');
//            });
        });

        $('.header .btn-nav').each(function(){
            $('#main-menu').on('click touchstart',function($e){
                $e.stopPropagation();
            });

            $(this).on('click touchstart', function($e){

                $('html').toggleClass('nav--opened');
                $(this).toggleClass('btn-nav--clicked');

                $e.stopPropagation();
                return false;
            });


            $(document).on('click touchstart', function(){
                $('html').removeClass('nav--opened');
                $('.header .btn-nav').removeClass('btn-nav--clicked');
            });
        });

        // Scroll Header
        // Scroll Header

        if( true || $window.width() >= 768){
            $(window).on('scroll.scrolled', function($e){
                var $top = $(this).scrollTop();

                //var $offset_top_1 = 50;
                var $offset_top_1 = $('.header__top').offset().top + $('.header__top').height();
                if($('#wpadminbar').length)
                    $offset_top_1 -= $('#wpadminbar').height();
                //var $offset_top_2 = $('#main-banner').offset().top + $('#main-banner').height() - $('#wpadminbar').height() - $('.scrolled-header').height();

                if($top < $offset_top_1){
                    $body.removeClass('scrolled');
                }else{
                    $('body').addClass('scrolled');
                }
            }).trigger('scroll.scrolled');
        }

        $('a[href^="#"]').on('click', function(){
            var $tag_id = $(this).attr('href');
            var $tag = $($tag_id);

            if($tag.length){
                var $offset_top = $tag.offset().top;
                $("html, body").animate({scrollTop: $offset_top}, 800);

                return false;
            }

            return true;
        });

        // Master Slider
        if(window.MasterSlider){
            var $masterslider = new MasterSlider();

            $masterslider.control('arrows' ,{insertTo:'#masterslider-page'});
            $masterslider.control('bullets');

            $masterslider.setup('masterslider-page' , {
                width: 1920,
                height: 450,
                space: 2,
                view:'fade',
                layout: 'fullwidth',
                fullscreenMargin:0,
                speed: 20,
                autoplay: true,
                loop: true,
                overPause: false
            });

        }

        // Parallax Background

        $('.js-parallax-background').parallax_background();

        // Element Scroll Slider
        if($.fn.bxSlider){
            $('.scroll-section .bxslider').each(function(){
                var $slider = $(this);
                preload_pictures($slider, function(){
                    $slider.removeClass('loading');
                    if($slider.find('.scroll-item').length >1){
                        var $mode = $slider.data('mode');
                        var $auto = $slider.data('auto');
                        var $controls = $slider.data('controls');
                        var $pager = $slider.data('pager');
                        var $pause = $slider.data('pause');
                        var $max = $slider.data('max');
                        var $min = $slider.data('min');
                        var $move = $slider.data('move');
                        var $width = $slider.data('width');

                        $mode = $mode ? $mode : 'horizontal';
                        $auto = $auto ? 1 : 0;
                        $controls = $controls ? 1 : 0;
                        $pager = $pager ? 1 : 0;
                        $pause = $pause ? parseInt($pause) : 8000;
                        $max = $max ? $max : 1;
                        $min = $min ? $min : 1;
                        $move = $move ? $move : 0;
                        $width = $width ? $width : 0;
                        var $bx_params = {
                            mode: $mode,
                            auto: $auto,
                            autoHover: true,
                            pause:  $pause,
                            touchEnabled: false,
                            controls: $controls,
                            pager: $pager
                        };


                        var $window_width = $(window).width();
                        if($window_width > 991) {
                            $min = $max;
                        }else if($window_width > 767) {
                            $min = $max-1;
                        }
                        if($move) {
                            $bx_params.minSlides = $min;
                            $bx_params.maxSlides = $max;
                            $bx_params.moveSlides = $move;
                            $bx_params.slideMargin = 0;
                            if(!$width) {
                                $width = 200;
                                if($max == 2) {
                                    $width = 570;
                                }else if($max == 3) {
                                    $width = 380;
                                }else if($max == 4) {
                                    $width = 285;
                                }else if($max == 6) {
                                    $width = 190;
                                }
                            }
                            $bx_params.slideWidth = $width;
                        }
                        var $bx = $slider.bxSlider($bx_params);

                        /*if($auto) {
                         $bx.closest('.bx-wrapper').find('.bx-controls a.bx-pager-link').click(function(){
                         if($bx._restart_timer){
                         clearTimeOut($bx._restart_timer);
                         }
                         $bx._restart_timer = setTimeout(function(){
                         $bx.startAuto();
                         }, 500);
                         return false;
                         });
                         }*/
                    }
                });
            });

        }

        // Scroll Up
        if( $('.scrollup').length == 0 ){
            $('body').append('<a href="#" class="scrollup">Scroll Up</a>')
        }

        $(window).scroll(function(){
            if ($(this).scrollTop() > 100) {
                $('.scrollup').fadeIn();
            } else {
                $('.scrollup').fadeOut();
            }
        });

        $('.scrollup').click(function(){
            $("html, body").animate({ scrollTop: 0 }, 600);
            return false;
        });

        // FAQ
        var $faq_group = $('.faqs__group');
        $body.on('click', '.faqs__group-title', function(){
            var $parent = $(this).closest('.faqs__group');

            if($faq_group.length == 1 || !$parent.hasClass('active')){
                $(this).next().slideDown();
                $parent.addClass('active');

                var $questions = $parent.find('.faqs__item-question');
                if($questions.length  < 2){
                    $questions.trigger('click');
                }

            }else{
                $(this).next().slideUp();
                $parent.removeClass('active');
            }

            return false;
        });

        $body.on('click', '.faqs__item-question', function(){
            var $parent = $(this).closest('.faqs__item');
            var $answer = $parent.find('.faqs__item-answer');
            $answer.slideToggle();
            $(this).parents('.faqs__item').toggleClass('active');
            return false;
        });

        var $box_modal = $('.box-modal');
        if($box_modal.length){
            $body.on('click', '.box-modal__overlay, .box-modal__close', function(){
                $box_modal.stop().hide();

                return false;
            })
        }

        /*$body.on('click', '.expand-group__title a', function(){
            var $parent = $(this).closest('.expand-group');
            $parent.find('.expand-group__content').slideToggle();

            return false;
        });*/

        $body.on('click', '.expand-group__title', function($e){
            $e.stopPropagation();

            var $parent = $(this).closest('.expand-group');

            if(!$parent.hasClass('active')){
                $(this).next().slideDown();
                $parent.addClass('active');

            }else{
                $(this).next().slideUp();
                $parent.removeClass('active');
            }

            return false;
        });
        $body.on('click', '.book-group__title', function($e){

            $e.stopPropagation();

            var $parent = $(this).closest('.book-group');

            if(!$parent.hasClass('active')){
                $(this).next().slideDown();
                $parent.addClass('active');

                var $book_now = $parent.find('.book-now-detail__title');
                if($book_now.length  < 2){
                    $book_now.trigger('click');
                }

            }else{
                $(this).next().slideUp();
                $parent.removeClass('active');
            }

            return false;
        });
        $body.on('click', '.book-now-detail__title', function($e){
            $e.stopPropagation();

            var $parent = $(this).closest('.book-now-detail');
            var $book_item = $parent.find('.book-now-detail__list');
            $book_item.slideToggle();
            $parent.toggleClass('active');

            return false;
        });

        $('.menu-search-button a').on('click', function($e){
            $e.stopPropagation();

            var $parent = $(this).closest('li');
            $parent.find('.menu-form').toggle();

            return false;
        });

        $('.menu-form').on('click', function($e){
            $e.stopPropagation();
        });

        $(document).on('click touchend', function(){
            $('.menu-form').hide();
        });

        $body.on('mouseover', '.training-locations__btn', function(){
            $(this).parent().toggleClass('active');
            return false;
        });
        $body.on('mouseleave', '.training-locations', function(){
            $(this).removeClass('active');
            return false;
        });

        $('#how_you_find_us').on('change', function(){
            var $value = $(this).val();
            if($value === 'Other'){
                $('#how_you_find_us_other_field').show();
            }else{
                $('#how_you_find_us_other_field').hide();
            }
        }).trigger('change');
    });




})(jQuery, window);